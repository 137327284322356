<template>
  <div class="modal" :class="{'is-active':show}">
    <div class="modal-background"></div>
    <div class="modal-card has-text-left">
      <header class="modal-card-head">
        <p class="modal-card-title is-size-4 is-size-5-mobile">{{title}}</p>
        <button class="delete" @click="$emit('close')"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="submit">
          <button type="submit" ref="save_edit_device" hidden></button>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Name</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded">
                  <input class="input" type="text" placeholder="Plants's Name" v-model="input.name" required>
                </p>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Detail</label>
            </div>
            <div class="field-body">
              <div class="field">
                <p class="control is-expanded has-icons-left">
                  <textarea class="textarea has-fixed-size" placeholder="Plants's Detail" v-model="input.detail" required></textarea>
                </p>
              </div>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <div class="w-100">
          <button type="button" class="button is-small is-info float-right mx-1" @click="$refs.save_edit_device.click()">Save</button>
          <button type="button" class="button is-small is-default float-right mx-1" @click="$emit('close')">Cancel</button>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalBranch',
  props: {
    show: Boolean,
    branch: {
      type: Object,
      default:()=>{ return { id: null }}
    }
  },
  data(){
    return {
      title: "Create Plants",
      input: {},
    }
  },
  methods:{
    set_input(){
      if(this.branch.id){
        Object.keys(this.branch).forEach((key) => {
          this.input[key] = this.branch[key];
        });
        this.title = "Edit Plants: "+this.branch.name;
      }
    },
    submit(){
      this.$emit('submit',this.input);
      // this.$emit('close');
    }
  },
  watch:{
    show(){
      this.title = "Create Plants";
      this.input = {};
      this.set_input();
    }
  }
}
</script>
