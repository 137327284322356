<template>
  <div id="mornitor">
    <div class="card">
      <div class="card-content" style="text-align: left;">
        <strong class="title is-4">
          {{branchs.length}} Branch(s)
          <button type="button" class="button is-small is-info" style="float:right;" @click="new_branch=true">
            <span class="mr-2 is-hidden-mobile">Create Branch</span>
            <i class="fas fa-plus"></i>
          </button>
        </strong>
        <hr>
        <div class="has-text-centered" v-if="!branchs.length">
          You didn't create any branch!
        </div>
        <div class="table-container" v-else>
          <table class="table is-bordered is-hoverable w-100">
            <thead>
              <tr>
                <th class="has-text-info">Name <i class="fas fa-charging-station"></i></th>
                <th class="has-text-info">Description</th>
                <th class="has-text-info has-text-centered">Option</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(val,key) in branchs" :key="key">
                <td class="cursor_pointer" @click="redirect('/member/branch/'+val.id)"> {{val.name}} </td>
                <td class="cursor_pointer" @click="redirect('/member/branch/'+val.id)"> {{val.detail}} </td>
                <td class="has-text-centered">
                  <button class="button is-small is-info mx-1" @click="edit_branch(val)">Update</button>
                  <button class="button is-small is-danger mx-1" @click="remove_branch(val)">Delete</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <modal-branch :show="new_branch" :branch="branch" @submit="submit_branch" @close="new_branch=false; branch={};"></modal-branch>

  </div>
</template>

<script>
import ModalBranch from '@/components/Modal/Branch'

export default {
  name: 'MemberMornitor',
  components:{ ModalBranch },
  props: {
    userData: Object,
    loaded: Object,
    branchs: Array,
  },
  data(){
    return {
      new_branch: false,
      branch: {},
    }
  },
  created(){
    if(!this.loaded.get_branch){
      this.$emit('get_branch');
    }
  },
  methods:{
    edit_branch(val){
      this.branch = val;
      this.new_branch = true;
    },
    submit_branch(input){
      if(!input.id){
        this.submit_new_branch(input)
      }else{
        this.submit_edit_branch(input)
      }
      this.new_branch = false;
    },
    // submit_new_branch(input){
    //   let add_branch =  {
    //     id: Date.now(),
    //     u_id: this.userData.id,
    //     name: input.name,
    //     detail: input.detail,
    //     updated: "0000-00-00 00:00:00",
    //     created: "2021-09-12 23:21:43",
    //     deleted: 0
    //   }
    //   this.branchs.push(add_branch);
    //   this.$swal("","เพิ่มข้อมูลสาขาใหม่","success");
    // },
    // submit_edit_branch(input){
    //   Object.keys(input).forEach((key) => {
    //     this.branch[key] = input[key];
    //   });
    //   this.branch = {};
    //   this.$swal("","Successful Save","success");
    // },
  }
}
</script>
